var _class;
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
import { next } from "@ember/runloop";
import Service from "@ember/service";
import A11yDialog from "a11y-dialog";
import { bind } from "discourse-common/utils/decorators";
let DialogService = (_class = class DialogService extends Service {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "dialogInstance", null);
    _defineProperty(this, "message", null);
    _defineProperty(this, "title", null);
    _defineProperty(this, "titleElementId", null);
    _defineProperty(this, "type", null);
    _defineProperty(this, "bodyComponent", null);
    _defineProperty(this, "bodyComponentModel", null);
    _defineProperty(this, "confirmButtonIcon", null);
    _defineProperty(this, "confirmButtonLabel", null);
    _defineProperty(this, "confirmButtonClass", null);
    _defineProperty(this, "confirmButtonDisabled", false);
    _defineProperty(this, "cancelButtonLabel", null);
    _defineProperty(this, "cancelButtonClass", null);
    _defineProperty(this, "shouldDisplayCancel", null);
    _defineProperty(this, "didConfirm", null);
    _defineProperty(this, "didCancel", null);
    _defineProperty(this, "buttons", null);
    _defineProperty(this, "class", null);
    _defineProperty(this, "_confirming", false);
  }
  async dialog(params) {
    const {
      message,
      bodyComponent,
      bodyComponentModel,
      type,
      title,
      confirmButtonClass = "btn-primary",
      confirmButtonIcon,
      confirmButtonLabel = "ok_value",
      confirmButtonDisabled = false,
      cancelButtonClass = "btn-default",
      cancelButtonLabel = "cancel_value",
      shouldDisplayCancel,
      didConfirm,
      didCancel,
      buttons
    } = params;
    let element = document.getElementById("dialog-holder");
    if (!element) {
      await new Promise(resolve => next(resolve));
      element = document.getElementById("dialog-holder");
    }
    if (!element) {
      const msg = "dialog-holder wrapper element not found. Unable to render dialog";
      // eslint-disable-next-line no-console
      console.error(msg, params);
      throw new Error(msg);
    }
    this.setProperties({
      message,
      bodyComponent,
      bodyComponentModel,
      type,
      dialogInstance: new A11yDialog(element),
      title,
      titleElementId: title !== null ? "dialog-title" : null,
      confirmButtonClass,
      confirmButtonDisabled,
      confirmButtonIcon,
      confirmButtonLabel,
      cancelButtonClass,
      cancelButtonLabel,
      shouldDisplayCancel,
      didConfirm,
      didCancel,
      buttons,
      class: params.class
    });
    this.dialogInstance.show();
    this.dialogInstance.on("hide", () => {
      if (!this._confirming && this.didCancel) {
        this.didCancel();
      }
      this.reset();
    });
  }
  alert(params) {
    // support string param for easier porting of bootbox.alert
    if (typeof params === "string") {
      return this.dialog({
        message: params,
        type: "alert"
      });
    }
    return this.dialog({
      ...params,
      type: "alert"
    });
  }
  confirm(params) {
    return this.dialog({
      ...params,
      shouldDisplayCancel: true,
      buttons: null,
      type: "confirm"
    });
  }
  notice(message) {
    return this.dialog({
      message,
      type: "notice"
    });
  }
  yesNoConfirm(params) {
    return this.confirm({
      ...params,
      confirmButtonLabel: "yes_value",
      cancelButtonLabel: "no_value"
    });
  }
  deleteConfirm(params) {
    return this.confirm({
      ...params,
      confirmButtonClass: "btn-danger",
      confirmButtonLabel: params.confirmButtonLabel || "delete"
    });
  }
  reset() {
    this.setProperties({
      message: null,
      bodyComponent: null,
      bodyComponentModel: null,
      type: null,
      dialogInstance: null,
      title: null,
      titleElementId: null,
      confirmButtonDisabled: false,
      confirmButtonIcon: null,
      confirmButtonLabel: null,
      cancelButtonClass: null,
      cancelButtonLabel: null,
      shouldDisplayCancel: null,
      didConfirm: null,
      didCancel: null,
      buttons: null,
      class: null,
      _confirming: false
    });
  }
  willDestroy() {
    this.dialogInstance?.destroy();
    this.reset();
  }
  didConfirmWrapped() {
    if (this.didConfirm) {
      this.didConfirm();
    }
    this._confirming = true;
    this.dialogInstance.hide();
  }
  cancel() {
    this.dialogInstance.hide();
  }
  enableConfirmButton() {
    this.set("confirmButtonDisabled", false);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "didConfirmWrapped", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "didConfirmWrapped"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableConfirmButton", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "enableConfirmButton"), _class.prototype)), _class);
export { DialogService as default };